<template>
    <div class="container">
        <div class="success-icon">
            <van-icon color='#00DE93' size="80px" name="checked" />
            <div>提交成功</div>
        </div>
    </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
    export default {
        name:'applySuccess',
        data() {
            return {
                list:[]
            }
        },
        components: {
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
        },
        methods: {
            toBack() {
                this.$router.push({path:'/user'})
            }
        },
        
    }
</script>

<style lang="less" scoped>
    .success-icon{
        text-align: center;
        background-color: #fff;
        padding: 250px 0;
        div{
            font-size: 16px;
            font-weight: bold;
            color: #00DE93;
        }
    }
    .report-btn{
        width: 100%;
        height: 50px;
        background-color: #00DE93;
        position: fixed;
        bottom: 0;
        text-align: center;
        line-height: 50px;
        color: #fff;
    }
</style>